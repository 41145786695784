export const premiumStore = {
    premium: {
      isPremium: false,
      premiumActivationDate: false,
      premiumCount: false,
    }
  };

export const UPDATE_PREMIUM = "UPDATE_PREMIUM";

const premiumReducer = (state = premiumStore, action) => {
    switch (action.type) {
      case UPDATE_PREMIUM:
        return {
          premium: {
            ...action.payload
          }
        };
      default:
        return state;
    }
};

export const UpdatePremium = premiumPayload => {
    return {
      type: UPDATE_PREMIUM,
      payload: premiumPayload
    };
};

export default premiumReducer;
