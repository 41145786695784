import React, { useEffect, useState, useCallback } from 'react';
import './BuyerBrandProducts.scss';
import Connect from "../../../../store/config/connect";
import BuyerBrandDescription from '../BuyerBrandDescription/BuyerBrandDescription';
import BuyerHeader from '../../BuyerHeader/BuyerHeader';
import services from '../../../../services/services';
import Product from './Product/Product';

const BuyerBrandProducts = props => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [brand, setBrand] = useState(null);
    const [loadProducts, setLoadProducts] = useState();
    const [loadBrand, setLoadBrand] = useState();
    
    function handleMobileMenuOpen(arg) {
        return setMobileMenuOpen(arg);
    }

    const [products, setProducts] = useState([]);

    const fetchProducts = useCallback((companyId, userId, token) => {
        const payload = {
            userId: userId,
            companyId: companyId
        };

        services.postFindAllForBrand(payload, token, (status, response) => {
            if (status === 200) {
                setProducts(response.msg);
                setLoadProducts(true);
            }
        });

    }, []);

    useEffect(() => {
        if(!user && props.user && props.user.token){
            setUser(props.user);
                
        }
    }, [props.user]);

    const [companyId, setCompanyId] = useState(null);
    useEffect(() => {
        if(!companyId && props.match.params && props.match.params.companyId){
            setCompanyId(props.match.params.companyId);
        }
    }, [props.match]);

    const [loaded, setLoaded] = useState(false);

    const getCompanyById = useCallback((companyId, token) => {
        //Get Logo & Name brand.
        services.getCompanyById(companyId, token, (status, response) => {
            if (status === 200) {
                console.log(response)
                setBrand({
                    companies: [{
                        name: response.msg.name,
                        logo: response.msg.logo,
                        id: response.msg.id,
                        sit_verified: response.msg.sit_verified
                    }]
                });
                setLoadBrand(true);
            }
        });
    }, []);

    useEffect(() => {
        if(companyId && user){
            fetchProducts(companyId, user.id, user.token);
            getCompanyById(companyId, user.token);
        }
    }, [companyId, user, fetchProducts, getCompanyById])


    //Verify services and set loaded for render component.
    useEffect(() => {
        if(loadProducts && loadBrand){
            setLoaded(true);
        }
    }, [loadProducts, loadBrand])

    return (
        <div className="BuyerBrandProducts">
            <BuyerHeader 
                mobileMenuOpen={mobileMenuOpen} 
                handleMobileMenuOpen={handleMobileMenuOpen}
            />
            {
                loaded &&
                    <div className="container BuyerBrandProducts__container">
                        <div className="BuyerBrandProducts__brand-container">
                            <div className="BuyerBrandProducts__brand-container--left">
                                <BuyerBrandDescription companyId={companyId} noMobile={true} user={user} />
                            </div>
                            <div className="BuyerBrandProducts__brand-container--right">
                                {
                                    products.map(product => {
                                        return (
                                            <Product 
                                                key={product.id} 
                                                product={product}
                                                brand={brand}
                                                 />
                                                
                                        ) 
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}


const mapStateToProps = ({ auth }, props) => {
    return {
      ...props,
      ...auth
    };
};

export default Connect(mapStateToProps)(BuyerBrandProducts);