import React from 'react';
import Router from '../router/router';
import './App.css';
import '../utils/styles/marginPadding.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = props => {
    let backTo = () => {
        let search = window.location.search.substring(1);
        let getParamsObject = {};

        if (search) {
            try {
                getParamsObject = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
            } catch (e) {
                console.error("Erro ao parsear os parametros do GET");
            }
        }

        if (getParamsObject.backTo) {
            window.location.href = getParamsObject.backTo;
        }
    }

    window.addEventListener("popstate", backTo);

    return (
        <React.Fragment>
            <Router/>
        </React.Fragment>
    );
}

export default App;
