import React, { useState, useEffect } from "react";
import Connect from "../../store/config/connect";
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from "react-helmet";

const SetFavicon = props => {
    const { pathname } = useLocation();
    let favAndTitles = [
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/home-marcas"
                }),
                title: "Home Marcas"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/manifesto"
                }),
                title: "Manifesto"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/sobre"
                }),
                title: "Sobre a Local.e"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/cadastro-marcas"
                }),
                title: "Cadastro Marcas"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/cadastro-marcas-boost"
                }),
                title: "Local.e"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/marcas"
                }),
                title: "Marcas"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/marcas/editar"
                }),
                title: "Editar Marca"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/marcas/meu-perfil"
                }),
                title: "Meu Perfil"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/marcas/criar-produto"
                }),
                title: "Criar Produto"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/marcas/produtos"
                }),
                title: "Produtos"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/marcas/produto/:itemId"
                }),
                title: "Produto"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/marcas/produto/:id/editar"
                }),
                title: "Editar Produto"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/boost/criar-senha/:hash"
                }),
                title: "Local.e"
            },
            {
                favicon: "favicon_red.png",
                match: useRouteMatch({
                    exact: true,
                    path: "/boost/deletar-conta/:hash"
                }),
                title: "Local.e"
            }
        ];

    const [favicon, setFavicon] = useState({
        small: "favicon_red.ico",
        medium: "favicon_red64.png",
        big: "favicon_red496.png"
    });
    const [title, setTitle] = useState("Local.e");

    useEffect(() => {
        var matchedRoute = favAndTitles.filter((route) => {
            if (route.match) {
                return route.match.isExact;
            } else {
                return false;
            }
        });
        matchedRoute = matchedRoute.length ? matchedRoute[0] : null;
        if (matchedRoute) {
            var name = matchedRoute.favicon.split(".")[0];
            var ext = matchedRoute.favicon.split(".")[1];
            var faviconTemp = {
                big: `${name}496.${ext}`,
                medium: `${name}64.${ext}`,
                small: `${name}.ico`
            }
            setFavicon(faviconTemp);
            setTitle(matchedRoute.title)
        } else {
            var faviconTemp = {
                big: "favicon_red496.png",
                medium: "favicon_red64.png",
                small: "favicon_red.ico"
            }
            setFavicon(faviconTemp);
            setTitle("Local.e");
        }
    }, [pathname])

    return (
        <Helmet>
            <title>{ title }</title>
            <link type="image/png" rel="shortcut icon" sizes="496x496" href={require(`../../images/favicons/${favicon.big}`)}/>
            <link type="image/png" rel="shortcut icon" sizes="64x64" href={require(`../../images/favicons/${favicon.medium}`)}/>
            <link type="image/x-icon" rel="shortcut icon" sizes="64x64" href={require(`../../images/favicons/${favicon.small}`)}/>
        </Helmet>
    );
}

const mapStateToProps = ({ auth }, props) => {
    return {
        auth,
        ...props
    };
};

export default Connect(mapStateToProps)(SetFavicon);
