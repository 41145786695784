import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as EditPen } from "../../../../images/editPen.svg";
import { ReactComponent as Heart } from "../../../../images/heart.svg";
import { ReactComponent as Eye } from "../../../../images/eye.svg";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import "./BuyerBrandDescription.scss";
import Services from "../../../../services/services";
import Collapse from '../../../Collapse/Collapse';
import CheckedIcon from '../../../Buyers/CheckedIcon/CheckedIcon'

const BuyerBrandDescription = props => {
    const [company, setCompany] = useState(null);
    const [noMobile, setNoMobile] = useState(false);
    const [openStates, setOpenStates] = useState(false);
    const [user, setUser] = useState(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setNoMobile(props.noMobile ? props.noMobile : false);
    }, [props.noMobile]);

    useEffect(() => {
        if (props.companyId && props.user.token) {
            setUser(props.user);
        }
    }, [props.company, props.user]);

    useEffect(() => {
        if (user) {
            async function fetchCompany() {
                return await Services.getCompanyById(props.companyId, user.token, (status, response) => {
                    setCompany(response.msg);
                });
            }
            fetchCompany();
        }
    }, [user]);

    return (
        (company && noMobile) ?
            <div id="BuyerBrandDescription">
                <span className="BuyerBrandDescription--title"> Sobre a marca </span>
                <div id="BuyerBrandDescription__container">
                    <div id="BuyerBrandDescription--logo">
                        <div id="logo--container" className={!company.logo ? 'logo--noImage' : ''}
                            style={(company.logo) && { backgroundImage: `url("${company.logo}")` }}
                        >
                        </div>
                    </div>
                    <div id="BuyerBrandDescription--header">
                        <div id="header--title">
                            {company.name}
                        </div>
                        <div id="header--stats">
                            <div id="stats__container" title="Quantidade de Visualizações">
                                <Eye />
                                <span>{company.views}</span>
                            </div>
                            <div id="stats__container" title="Favoritos">
                                <Heart />
                                <span>{company.stars}</span>
                            </div>
                        </div>
                        <div id="header--city">
                            {
                                company.addresses.length ?
                                    <span>{company.addresses[0].city.desc} - {company.addresses[0].city.state.uf}</span>
                                    :
                                    <span></span>
                            }
                        </div>
                    </div>

                    <div className="separator"></div>
                    <Link to={`/varejistas/marcas/${props.companyId}/produtos`} className="fields--row__link">
                        Catálogo Completo
                    </Link>
                    <div className="separator" style={{ 'marginBottom': '0' }}></div>
                    {
                        company.institutional_document &&
                        <React.Fragment>
                            <div id="fields--row">
                                <a href={company.institutional_document} className="fields--row__download" target="_blank" rel="noopener noreferrer" style={{ 'margin' : '20px 0' ,'font-size': '14px'}}>
                                    Apresentação Institucional
                                </a>
                            </div>
                        </React.Fragment>
                    }
                    { 
                        (company.institutional_video || company.institutional_document) && 
                        <React.Fragment>
                            <div className="separator" style={{ 'marginBottom': '0' }}></div>
                        </React.Fragment>
                    }
                    <div id="BuyerBrandDescription--fields" className={edit ? 'Collapse edit' : 'Collapse'}>
                        <Collapse name="Ver detalhes da Marca" maxHeight="1500" background="true">
                            <ul>
                                <div className="pd-02" style={edit ? { 'display': 'none' } : {}}>
                                    {
                                        company.site_url &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Website
                                                </div>
                                            <div id="fields--info" className="fields--info--left">
                                                <a href={company.site_url.indexOf("http") >= 0 ? company.site_url : `http://${company.site_url}`} target="_blank" rel="noopener noreferrer">
                                                    <span className="span-fields-break" >{company.site_url.replace(/http?s:\/\//g, '')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    }
                                    <div className="separator"></div>
                                    {
                                        company.facebook_url &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Facebook
                                                </div>
                                            <div id="fields--info" className="fields--info--left">
                                                <a href={company.facebook_url} target="_blank" rel="noopener noreferrer">
                                                    <span className="span-fields-break" >{company.facebook_url.replace(/http?s:\/\//g, '')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.instagram_url &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Instagram
                                                </div>
                                            <div id="fields--info" className="fields--info--left">
                                                <a href={company.instagram_url} target="_blank" rel="noopener noreferrer">
                                                    <span className="span-fields-break" >{company.instagram_url.replace(/http?s:\/\//g, '')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.linkedin_url &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                LinkedIn
                                                </div>
                                            <div id="fields--info" className="fields--info--left">
                                                <a href={company.linkedin_url} target="_blank" rel="noopener noreferrer">
                                                    <span className="span-fields-break" >{company.linkedin_url.replace(/http?s:\/\//g, '')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    }
                                    <div className="separator"></div>
                                    {
                                        company.qty_products > 0 &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Quantidade de produtos
                                                </div>
                                            <div id="fields--info">
                                                {
                                                    company.qty_products
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.billing &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Faturamento Anual
                                                </div>
                                            <div id="fields--info">
                                                {
                                                    company.billing.desc
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.qty_pdv > 0 &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Quantidade de PDVs
                                                </div>
                                            <div id="fields--info">
                                                {
                                                    company.qty_pdv || "0"
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.selling_points.length > 0 &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Principais PDVs
                                                </div>
                                            <div id="fields--info" className="fields--info--left">
                                                {
                                                    company.selling_points.map((pdv) => {
                                                        return (
                                                            <span key={pdv.id}>{pdv.desc}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.states && company.states.length > 0 &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Distribui para todo o Brasil?
                                                </div>
                                            <div id="fields--info">
                                                {
                                                    company.states && (
                                                        company.states.length === 27
                                                            ? <React.Fragment>
                                                                <span>Sim</span>
                                                            </React.Fragment>
                                                            : company.states.length !== 0
                                                            && <React.Fragment>
                                                                <span>Não</span>
                                                                <br />
                                                                {
                                                                    <div className="arrowDownState">
                                                                        {
                                                                            openStates
                                                                                ? <MdKeyboardArrowUp onClick={() => {
                                                                                    setOpenStates(!openStates);
                                                                                }} />
                                                                                : <MdKeyboardArrowDown onClick={() => {
                                                                                    setOpenStates(!openStates);
                                                                                }} />
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    openStates &&
                                                                    <div className="BuyerBrandDescription__statesDelivery">
                                                                        {
                                                                            company.states.map(i => {
                                                                                return (
                                                                                    <span key={i.desc}>&nbsp;{i.uf}&nbsp;</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.brand_details.length > 0 && company.brand_details[0].sit_work_with_supplier !== null &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Trabalha com distribuidores?
                                                </div>
                                            <div id="fields--info">
                                                {
                                                    company.brand_details[0].sit_work_with_supplier === 1 ?
                                                        'Sim'
                                                        :
                                                        'Não'
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.sit_own_brand_only !== null &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Interesse em marca própria?
                                                </div>
                                            <div id="fields--info">
                                                {
                                                    company.sit_own_brand_only === 1 ?
                                                        'Sim'
                                                        :
                                                        'Não'
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        company.legal_nature &&
                                        <div id="fields--row">
                                            <div id="fields--title">
                                                Natureza Jurídica
                                            </div>
                                            <div id="fields--info" className="fields--info--left">
                                                {company.legal_nature}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </ul>
                        </Collapse>
                    </div>
                </div>
            </div>
            : <div id="BuyerBrandDescription" className={`${noMobile ? 'noMobile' : ''} ${props.noDesktop ? 'noDesktop' : ''}`}>
                <div id="BuyerBrandDescription__container">
                    <div id="BuyerBrandDescription__text">
                        <span>
                            Os dados da empresa quando completos aparecerão aqui.
                    </span>
                    </div>
                    <hr />
                    <div id="BuyerBrandDescription__newItem">
                        <Link to="marcas/editar">
                            <button type="button">Completar dados</button>
                        </Link>
                    </div>
                </div>
            </div>
    )
}

export default BuyerBrandDescription;
