import React, { useState, useEffect, useRef } from 'react';
import Connect from '../../store/config/connect';
import './Collapse.scss';

const Collapse = props => {
    const [openSideBar, setOpenSideBar] = useState(false);
    const menuSectionEl = useRef()
    const handleSideBar = (status, target) => {
        setOpenSideBar(!status)
 
        if(props.background){

            function hideMenuSection () {
                setTimeout(() => {
                    menuSectionEl.current.style.background = '#E7ECF1';
                }, 800);
            }

            status ? hideMenuSection() : menuSectionEl.current.style.background = 'transparent';
        }
    }
    
    return (
        <li className={props.className}>
            <span ref={menuSectionEl} className={ openSideBar ? "Collapse--menuSection active" : "Collapse--menuSection" } onClick={e => { handleSideBar(openSideBar, e) }}>{ props.className && props.className.includes('opportunities') ? (openSideBar ? 'Esconder todas as oportunidades' : 'Ver todas as oportunidades') : props.name }</span>
            <div className={ openSideBar ? "Collapse--options active" : "Collapse--options" } style={ openSideBar ? {  'maxHeight': props.maxHeight + 'px' } : {}}>
                {props.children}
            </div>
        </li>
    )
}

const mapStateToProps = ({}, props) => {
    return {
      ...props
    };
};

export default Connect(mapStateToProps)(Collapse);
