import { useState, useEffect } from "react";
import Connect from "../../store/config/connect";
import { useLocation } from 'react-router-dom';

const SendGA = props => {
    const { pathname } = useLocation();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("localev2-user"));

        if (user) {

            // caso user seja Marca
            if (user.company?.sit_brand) {

                const userPremium = user.company.premium?.sit_active == 1
                    || user.company.premia?.[0]?.sit_active == 1 ? true : false
                window.gtag('event', `${userPremium ? 'user_premium_view' : 'user_free_view'}`, {
                    page_location: pathname
                });

                window.gtag('set', 'user_properties', { 'user_plan': `${userPremium ? 'premium' : 'free'}`, 'segmento': 'marca' });
            }

            // caso user seja Varejista
            else {
                window.gtag('event', `buyer_view`, {
                    page_location: pathname
                });
                window.gtag('set', 'user_properties', { 'segmento': 'varejista' });
            }

        } else {
            window.gtag('event', `user_view`, {
                page_location: pathname
            });
        }

    }, [pathname]);

    return null;
}

const mapStateToProps = ({ auth }, props) => {
    return {
        auth,
        ...props
    };
};

export default Connect(mapStateToProps)(SendGA);
