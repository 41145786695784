const getModalTitle = (hasRequestSample, deliveryInBuyerRegion) => {

    console.log(`SOLICITOU AMOSTRA: ${hasRequestSample}, Entrega na sua região: ${deliveryInBuyerRegion} `)
 
    if(deliveryInBuyerRegion === false){

        if(hasRequestSample){
            return ` &nbsp; &nbsp; Você já entrou em contato com essa marca. <br/>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Marca não distribui em sua região`
        }

        return `&nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp;  Marca não distribui em sua região`
    }

    if(hasRequestSample !== 0){
        return `Você já entrou em contato com essa marca. <br/>Gostaria de pedir algo mais?`
    }

       
    return `O que você gostaria de pedir para marca?`


}

export default getModalTitle;
