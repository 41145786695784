import React, { useState, useEffect } from 'react';
import './CheckedIcon.scss';
import { ReactComponent as CloseIcon } from '../../../images/checked.svg';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

const CheckedIcon = props => {

        const uuiddd = uuidv4();
        const session = 1 //sessão na array do FAQ
        const itemToOpenInArray = 3 // item na sessão para ser aberto ao redirecionar

    return (
        <React.Fragment>
              <Link target="_blank" to={{
                pathname: `/faq-varejistas?sessionIdToOpen=${session}&itemToOpenInArray=${itemToOpenInArray}`, 
            } }  >
                <div id="Checked-Icon" >
                     <a  href={`/faq-varejistas?sessionIdToOpen=${session}&itemToOpenInArray=${itemToOpenInArray}`} data-tip='Marca verificada  pela Local.e!'  data-for={uuiddd} >
                         <CloseIcon
                          width={props.width ? props.width : '14'}
                          height={props.height ? props.height : '14'} />
                    </a>
                    <ReactTooltip 
                        multiLine={true} 
                        delayHide={100} 
                        id={uuiddd} 
                        place="bottom" 
                        border={true}
                        borderColor='#A6ADC933'
                        className="tooltiptext" 
                        textColor='#212C3F'
                        backgroundColor='#FFFFFF' 
                        clickable={true} 
                        effect="solid" >
                        <div className="tooltip-container" >
                            Marca verificada pela Local.e!
                                <div className="link-right" >
                                <Link target="_blank" to={{
                                    pathname: `/faq-varejistas?sessionIdToOpen=${session}&itemToOpenInArray=${itemToOpenInArray}`, //sessão na array do FAQ Geral ou uso da Plataforma // item na sessão para ser aberto ao redirecionar
                                    } }  >
                                  <b><u> Saiba Mais</u></b>
                                  </Link>
                                </div>
                             </div>
                        </ReactTooltip>
                        </div >
            </Link>
        </React.Fragment>
    )
}

export default CheckedIcon;
