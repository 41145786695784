
import axios from 'axios';

class Services {
    constructor() {
        let baseUrl = process.env.REACT_APP_API_PATH,
            baseUrlv2 = process.env.REACT_APP_API_PATH,
            service = axios.create({
                baseUrl: baseUrl
            });

        service.interceptors.response.use(
            this.handleSuccess,
            this.handleError
        );

        this.service = service;
        this.baseUrl = baseUrl;
        this.baseUrlv2 = baseUrlv2;
    }

    redirectTo = (document, path) => {
        document.location = path;
    }

    handleSuccess(response){
        return response;
    }

    handleError = (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    window.localStorage.removeItem('localev2-user');
                    window.localStorage.removeItem('locale-sistema');
                    this.redirectTo(document, '/login')
                    console.log(error)
                    break;
                default:
                    return error.response;
            }
        }
        return Promise.reject(error);
    }

    findTypeKnown = (callback) => {
        return this.service.get(`${this.baseUrlv2}/typeKnown/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getStates = (callback) => {
        return this.service.get(`${this.baseUrlv2}/state/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getCities = (callback) => {
        return this.service.get(`${this.baseUrlv2}/city/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getMarketingInvest = (callback) => {
        return this.service.get(`${this.baseUrlv2}/typeMarketingInvest/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getBillings = (callback) => {
        return this.service.get(`${this.baseUrlv2}/billing/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getSellingPoints = (callback) => {
        return this.service.get(`${this.baseUrlv2}/sellingPoint/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    findItemMaxValue = (token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/maxValues`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
        }).then((response) => callback(response.status, response.data));
    }

    postValidateVoucher = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premium/voucher`,
            responseType: 'json',
            data: payload,
            headers: {
                Authorization: `${token}`
            },
        }).then((response) => callback(response.status, response.data));
    }

    postLogPremiumClick = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/logPremiumClick`,
            responseType: 'json',
            data: payload,
            headers: {
                Authorization: `${token}`
            },
        }).then((response) => callback(response.status, response.data));
    }

    postLogClick = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/logClick`,
            responseType: 'json',
            data: payload,
            headers: {
                Authorization: `${token}`
            },
        }).then((response) => callback(response.status, response.data));
    }

    postTrackRdStation = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/rdstation/createLog`,
            responseType: 'json',
            data: payload,
            headers: {
                Authorization: `${token}`
            },
        }).then((response) => callback(response.status, response.data));
    }

    postCreateLog = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/createLog`,
            responseType: 'json',
            data: payload,
            headers: {
                Authorization: `${token}`
            },
        }).then((response) => callback(response.status, response.data));
    }

    createBrand = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/createBrand`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    createUserTagInterest = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/createUserTagInterest`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postFindAllBuyersWithFilters = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/findAllBuyersWithFilters`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUpdateCloseOrigin = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/updateCloseOrigin`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postRecoveryPass = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/recoveryPass`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postValidateRecoveryPass = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/validateRecoveryPass`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postGetPremiumValidation = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premium/getValidation`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postCreateBrandContact = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premiumContact/createBrandContact`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postFindIndividualBuyers = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premiumContact/findIndividualBuyers`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postFindIndividualBuyersForMyContacts = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premiumContact/findIndividualBuyersForMyContacts`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postFindMyContacts = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premiumContact/myContacts`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postItemByIdForBrand = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/findByIdForBrand`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postFindAllForBrand = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/findAllForBrand`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postPublishItem = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/publish`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postPauseItem = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/pause`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postItemDelete = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/delete`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postItemDuplicate = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/duplicate`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postFindCityByState = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/city/findCityByState`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postLoginByEmail = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/login/email`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postLoginByGoogle = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/login/google`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    logDownload = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/logdownload`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    logInterestedBrand = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/logInterestedBrand`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUser = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/find`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUserUpdate = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/update`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUserPassword = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/updatePassword`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUserDeactivate = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/deleteUser`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUpdateCompany = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/update`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUpdateBrand = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/updateBrand`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    updateBrandHistory = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/updateBrandHistory`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUpdateAbout = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/updateAbout`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postCreatePendingRegister = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/pendingRegister/create`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUpdatePendingRegister = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/pendingRegister/update`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUpdateCompanyLogo = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/updateLogo`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postItemForPanel = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/findAllForPanel`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postHomePanel = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/homePanel`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postHomePanelMsgRead = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/homePanelMsgRead`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postCompanyFind = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/find`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postItemsForBrand = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/findAll`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    findBrandCategoriesWithSubs = (callback) => {
        return this.service.get(`${this.baseUrl}/category/findBrandCategoriesWithSubs`).then(
            (response) => callback(response.status, response.data)
        );
    }

    postCertificateFindAllById = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/certificate/findAllByCategory`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    getCertificates = (callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/certificate/findAll`,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getTrails = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/academy/trail/findAll`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getSchedules = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/schedule/findAll`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getTip = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/tip/find`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getAcademyTags = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/academy/tags`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getAcademyContentById = (token, id, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/academy/content/byId/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getAcademyTrailById = (token, id, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/academy/trail/byId/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    setStatusStepFinished = (token, idStep, status, callback) => {
        return this.service.request({
            method: 'PUT',
            url: `${this.baseUrlv2}/academy/step/finishe/${idStep}/${status}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    setStatusStepVisualize = (token, idStep, status, callback) => {
        return this.service.request({
            method: 'PUT',
            url: `${this.baseUrlv2}/academy/step/visualize/${idStep}/${status}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    setStatusContentVisualize = (token, idContent, status, callback) => {
        return this.service.request({
            method: 'PUT',
            url: `${this.baseUrlv2}/academy/content/visualize/${idContent}/${status}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getAcademyContent = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/academy/content/findAll`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getAcademyContentFiltered = (token, tag = null, format = null, type = null, callback) => {
        const baseUrl = `${this.baseUrlv2}/academy/content/filter`;

        let queryParams = [];
        if (tag) queryParams.push(`idTag=${tag}`);
        if (format) queryParams.push(`contentFormat=${format}`);
        if (type) queryParams.push(`type=${type}`);
        queryParams.push('order=DESC');
    
        const queryString = queryParams.join('&');
    
        const url = `${baseUrl}${queryParams.length > 0 ? `?${queryString}` : ''}`;

        return this.service.request({
            method: 'GET',
            url: url,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postSpecialFeatureFindAllById = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/specialFeature/findAllByCategory`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    getSpecialFeatures = (callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/specialFeature/findAll`,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getStateFindAll = (callback) => {
        return this.service.get(`${this.baseUrl}/state/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getTypeManufacturingFindAll = (callback) => {
        return this.service.get(`${this.baseUrl}/typeManufacturing/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getTypePackageFindAll = (callback) => {
        return this.service.get(`${this.baseUrl}/typePackage/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getTypeStorageFindAll = (callback) => {
        return this.service.get(`${this.baseUrl}/typeStorage/findAll`).then(
            (response) => callback(response.status, response.data)
        );
    }

    getWhatsapp = (payload, token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/whatsapp/` + payload,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    getWhatsappList = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/whatsapp/usersWhatsappList`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postWhatsapp = async (payload, token, callback) => {
        return this.service.request({
            method: 'PUT',
            url: `${this.baseUrlv2}/whatsapp`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postSendOrder = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/itemEmailInfo/create`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postItemCreate = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/create`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postItemById = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/findById`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postItemUpdate = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/update`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUserNewPasswordBoost = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/newPasswordBoost`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postUserDeleteBoost = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/deleteBoost`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    getChats = (payload, token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/chat?idUser=${payload.userId}`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            }
        }).then((response) => callback(response.status, response.data));
    }

    getUnreadMsgs = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/chat/hasUnreadMsgs`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            }
        }).then((response) => callback(response.status, response.data));
    }

    getMessages = (payload, token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/chat/${payload.userId}${(payload.page !== undefined && payload.page !== null) ? `?page=${payload.page}&size=${payload.size}` : ""}`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            }
        }).then((response) => callback(response.status, response.data));
    }

    postDeactivatePremiumTemp = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premium/deactivate`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    requestCancelation = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premium/requestCancelation`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postFindNewEmail = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/newemail`,
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getFeaturedProducts = (callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/item/home`,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getPublishedCount = (callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/item/publishedCount`,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getMyUser = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/user/myUser`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            }
        }).then((response) => callback(response.status, response.data));
    }

    checkLegalNature = (token) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/company/checkLegalNature`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            }
        });
    }

    findBrandCategoriesWithSubsItems = (callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/category/findBrandCategoriesWithSubsItems`,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getBuyerCategories = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/category/findBuyerCategory`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            }
        }).then((response) => callback(response.status, response.data));
    }

    getOpportunities = (token, callback, param) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/premiumContact/opportunities${param ? '?status=' + param : ''}`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            }
        }).then((response) => callback(response.status, response.data));
    }

    postCheckedOpportunities = (buyerId, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premiumContact/opportunities/${buyerId}/respond`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postHideOpportunitie = (id, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premiumContact/opportunities/${id}/hide`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    findFiles = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/file/findFiles`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    postRejectBrand = (id, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premiumContact/opportunities/${id}/reject`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postAcceptBrand = (id, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/premiumContact/opportunities/${id}/accept`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postUpdateComplementBuyer = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/updateComplementBuyer`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postUpdateInterestCategories = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/interestCategories`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    createBuyer = (payload, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/createBuyer`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    updateDeliveryAddress = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/address/updateBuyer`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postContactUs = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/contactUs`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getCompanyById = (id, token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/company/${id}`,
            responseType: 'json',
            headers: {
                Authorization: `${token}`
            }
        }).then((response) => callback(response.status, response.data));
    }

    postFavoriteItem = (id, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/favorite/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postItemEmailInfo = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/itemEmailInfo/create`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postItemShared = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/itemShared/create`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    postCreateItemSample = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/companyControl/createItemSample`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    findAllForBuyer = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/findAllForBuyer`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    findAllWithFilters = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/findAllWithFilters`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    findNewItems = (token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/item/findNewItems`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    findHomeNewItems = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/item/news`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    findAcademyVideos = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/company/video`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    findHomeRecomendationsItems = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/item/recomendations`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    findHomeTendenciesItems = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/item/tendencies`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getHomeTendencies = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/tendency-categories`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getTendencies = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/item/tendencies`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getRegionTendencies = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/item/regionTendencies`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }



    setUserVisualizedCheckedModalInfo = (token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/checkAtt`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getUserVisualizedCheckedModalInfo = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/user/checkAtt`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getHomeTendenciesDetail = (id, token, callback) => {
        let url;

        switch (parseInt(id)) {
            case 1:
                url = `${this.baseUrlv2}/item/tendencies`;
                break;
            case 2:
                url = `${this.baseUrlv2}/item/regionTendencies`;
                break;
            default:
                url = `${this.baseUrlv2}/tendency-categories/${id}`;
        }

        return this.service.request({
            method: 'GET',
            url: url,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    setRatingBuyer = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/ratingBuyer`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }



    getAllRatingBuyer = (id, token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/ratingBuyer/findAll/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getAllRatingBuyerBusiness = (id, token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/ratingBuyer/findAllBusiness/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }


    createRate = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/user/userRate`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    SendNewBuyer = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/company/sendNewBuyer`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }


    verifyRate = (token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/user/verifyRate`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getRateById = (id, token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/ratingBuyer/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    getRateBusinessById = (id, token, callback) => {
        return this.service.request({
            method: 'GET',
            url: `${this.baseUrlv2}/ratingBuyer/business/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }

    deleteRating = (id, token, callback) => {
        return this.service.request({
            method: 'DELETE',
            url: `${this.baseUrlv2}/ratingBuyer/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }


    editRatingBuyer = (id, payload, token, callback) => {
        return this.service.request({
            method: 'PUT',
            url: `${this.baseUrlv2}/ratingBuyer/${id}`,
            headers: {
                Authorization: `${token}`
            },
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }


    postComplaintBuyer = (payload, token, callback) => {
        return this.service.request({
            method: 'POST',
            url: `${this.baseUrlv2}/complaintBuyer`,
            headers: {
                Authorization: `${token}`
            },
            data: payload,
            responseType: 'json'
        }).then((response) => callback(response.status, response.data));
    }



}

export default new Services();
