import React from 'react';
import './LoaderForButton.scss';

const LoaderForButton = props => {
    return (
        <div style={{ display: props.display ? props.display : "flex" }} id="loader-ring">
            <div className={props.color === "blue" ? "blueRing" : "lds-ring"}>
                <div></div>
            </div>
        </div>
    )
}

export default LoaderForButton;
