import React, { useState, useEffect }  from 'react';
import './ModalAddress.scss';
import Connect from '../../../store/config/connect';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';
import { useHistory } from 'react-router-dom';
import useForm  from "react-hook-form";
import Services from "../../../services/services";
import InputMask from 'react-input-mask';
import LoaderForButton from "../../Buyers/Utils/LoaderForButton/LoaderForButton";

// const StatesCity = require('./StatesCity.json');

const ModalAddress = props => {
    const history = useHistory();
    const { register, handleSubmit, watch, errors } = useForm();
    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2
    } = useForm();
    const [sitActive, setSitActive] = useState(false);
    const [numberValue, setNumberValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingAskBrand, setIsSubmittingAskBrand] = useState(false);
    const [loaderSucess, setILoaderSucess] = useState(false);
    const [hideStepOne, setIHideStepOne] = useState(false);
    const [citys, setCitys] = useState([]);
    const [states, setStates] = useState([]);

    const handleSitActive = value => { 
        setSitActive(value)
    }
    //Get States
    useEffect(() => {
        if (props.buyerModalStatus) {
            Services.getStates((status, data) => {
                setStates(
                    data.msg.map(i => {
                        return {
                            label: i.desc,
                            value: i.id
                        }
                    })
                )
            });
        }
    }, []);
 
    const onSubmit = (data) => { 
        setIsSubmitting(true);

        function removemask(value){
            value = value.replace('(','');
            value = value.replace(') ','');
            value = value.replace('-','');

            return value;
        }

        data.cep = numberValue;

        Services.updateDeliveryAddress(data, props.token, (status, data) => {
            if (status === 200) {

                //Sucess Message.
                setTimeout(() => {
                    setILoaderSucess(true);
                }, 600);

                console.log('data form ---------------------->', props.data);

                Services.postSendOrder(props.data, props.token, (status, response) => {
                    console.log('getWhatsapp----------->', status, response)
                    if (status === 200) {
                        //Sucess Message.
                        setTimeout(() => {
                            setILoaderSucess(true);
                        }, 1000);
                    }
                });
            }
        });
    }

    function handleClose(){
        setIsSubmitting(false);
        
        if(props.returnTo){
            history.push(props.returnTo);
        } else if(!props.returnToHome){
            props.setBuyerModalStatus(false);
            if(props.closeBuyerModal){
                props.closeBuyerModal.fnc();
            }
        } else {
            history.push("/marcas");
        }
    }

    function handleManualConfirm(){
        if(props.onManualConfirm.fnc){
            props.onManualConfirm.fnc();
            return props.setBuyerModalStatus(false);
        } else {
            return props.setBuyerModalStatus(false);
        }
    }

    function handleManualCancel(){
        if(props.onManualCancel.fnc){
            props.onManualCancel.fnc();
        } else {
            props.setBuyerModalStatus(false);
        }
    }

    const handleState = value => {
        Services.postFindCityByState({
            id: value
        },(status,data) => {
            setCitys(data.msg.map(i => {
                    return {
                        label: i.desc,
                        value: i.id
                    }
                })
            )
        });
    }

    return (
        <React.Fragment>

            <div id="BuyerModal" style={{display: props.buyerModalStatus ? 'flex' : 'none'}}>
                <CSSTransition
                    in={props.buyerModalStatus}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                >
                    <div id="BuyerModal__container">
                        <div id="BuyerModal__header">
                            <div id="BuyerModal__container--title" className={!props.title ? 'BuyerModal--noPadding' : ''}>
                                <span> { !loaderSucess ? props.title : 'Mensagem enviada com sucesso!' } </span>
                            </div>
                            <div id="BuyerModal__container--close">
                                <CloseIcon onClick={handleClose}/>
                            </div>
                        </div>
                        <div id="BuyerModal__container--desc" className={props.buyerModalAlign === "center" ? "BuyerModal__container--desc-center" : ""}>
                            { isSubmitting ? 
                                <React.Fragment>
                                    { !loaderSucess && <LoaderForButton />}
                                    { loaderSucess &&
                                        <React.Fragment>
                                            <span className="modal__whatsapp--sucess"> Sua mensagem foi enviada para <strong>Marca</strong> enviada com sucesso! </span>
                                            <button className="secondBtn secondBtn__confirm" onClick={handleClose}>OK</button>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {!hideStepOne &&
                                        <div className="modal__whatsapp">
                                            <span className="modal__whatsapp--description">Para solicitar uma amostra, é necessário cadastrar um endereço para envio. Preencha abaixo as informações do endereço para onde a amostra será enviada.</span>

                                            <form id="form" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="modal__whatsapp--colLeft">
                                                    <fieldset className="form__group">
                                                        <label className="form__group--title" htmlFor="accept">CEP</label>

                                                        <InputMask mask="99999-999" onChange={e => {setNumberValue(e.target.value)}} type="text" name="cep" id="cep" placeholder="99999-999" ref={register({ required: true })} />
                                                        {sitActive && numberValue == '' && <span className="required">Preencha seu CEP*</span>}
                                                    </fieldset>
                                                    <fieldset className="form__group">
                                                        <label className="form__group--title" htmlFor="accept">Endereço</label>

                                                        <input type="text" name="desc" id="desc" placeholder="Ex: Avenida Paulista" ref={register({ required: true })} />
                                                        {errors.address && <span className="required">Preencha seu Endereço*</span>}
                                                    </fieldset>
                                                    <fieldset className="form__group">
                                                        <label className="form__group--title" htmlFor="accept">Estado</label>

                                                        <select type="text" name="id_state" id="id_state" onChange={e => {handleState(e.target.value)}} ref={register({ required: true })}>
                                                            <option value="default">Selecione o Estado (UF)</option>
                                                            {states.map(state => {
                                                                return (
                                                                    <option key={state.value} value={state.value}>{state.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {errors.state && <span className="required">Preencha seu Endereço*</span>}
                                                    </fieldset>
                                                </div>

                                                <div className="modal__whatsapp--colRight">
                                                    <fieldset className="form__group number">
                                                        <label className="form__group--title" htmlFor="accept">Número</label>

                                                        <input type="number" name="number" id="number" placeholder="Ex: 123" ref={register({ required: true })} />
                                                        {errors.number && <span className="required">Preencha o Número*</span>}
                                                    </fieldset>
                                                    <fieldset className="form__group complement">
                                                        <label className="form__group--title" htmlFor="accept">Complemento</label>

                                                        <input type="text" name="complement" id="complement" placeholder="Ex: Conjunto 123" ref={register} />
                                                    </fieldset>
                                                    <fieldset className="form__group">
                                                        <label className="form__group--title" htmlFor="accept">Cidade</label>
                                                        <select type="text" name="id_city" id="id_city" ref={register({ required: true })}>
                                                            <option value="default">Selecione a cidade</option>
                                                            {citys.length > 0 && citys.map(city => {
                                                                return (
                                                                    <option key={city.value} value={city.value}>{city.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {errors.city && <span className="required">Preencha a Cidade*</span>}
                                                    </fieldset>
                                                </div>
                                                <fieldset className="form__group buttons">
                                                    <input type="button" className="firstBtn" defaultValue="Cancelar" onClick={props.onCancel ? props.onCancel : handleManualCancel} />
                                                    <input type="submit" className="secondBtn" value="Salvar e Enviar"/>
                                                </fieldset>
                                            </form>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </React.Fragment>
    )
}

export default Connect()(ModalAddress);
