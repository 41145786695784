import React, { useState, useEffect }  from 'react';
import './ModalWhatsApp.scss';
import Connect from '../../store/config/connect';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { useHistory } from 'react-router-dom';
import useForm  from "react-hook-form";
import Services from "../../services/services";
import InputMask from 'react-input-mask';
import LoaderForButton from "../Buyers/Utils/LoaderForButton/LoaderForButton";
import ModalAddress from '../Buyers/ModalAddress/ModalAddress';

const ModalWhatsApp = props => {
    const history = useHistory();
    const { register, handleSubmit} = useForm();
    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2
    } = useForm();
    const [sitActive, setSitActive] = useState(false);
    const [numberValue, setNumberValue] = useState('');
    const [askBrand, setAskBrand] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingAskBrand, setIsSubmittingAskBrand] = useState(false);
    const [loaderSucess, setILoaderSucess] = useState(false);
    const [loaderSucessStep1, setILoaderSucessStep1] = useState(false);
    const [hideStepOne, setIHideStepOne] = useState(false);
    const [modalStatusAddress, setModalStatusAddress] = useState(false);

    const [modalPropsAddress, setModalPropsAddress] = useState({
        setBuyerModalStatus: setModalStatusAddress,
        title: 'Cadastrar endereço',
        returnTo: false,
        buttons: false,
        onManualConfirm: false,
        token: props.user.token,
        onManualCancel:false,
        data: null
    });

    const handleSitActive = value => { 
        setSitActive(value)
    }
 
    const onSubmit = (data) => { 
        setIsSubmitting(true);

        function removemask(value){
            value = value.replace('(','');
            value = value.replace(') ','');
            value = value.replace('-','');

            return value;
        }

        if (numberValue !== '' && sitActive && data.sitActive) {
            data.number = removemask(numberValue);
            postWhatsapp(data, props.user.token);
        } else if(data.sitActive == "false") {
            postWhatsapp(data, props.user.token);
        }

        function postWhatsapp(data, token) {
            Services.postWhatsapp(data, token, (status, data) => {
                if(status === 200){
                    if(props.opportunities){
                        Services.postAcceptBrand(props.brandID, token, (status, data) => {
                            if(status === 200){
                                console.log('Enviado com sucesso!');
                                props.opportunitiesCB();
                            }
                        });
                    }
                }
            });

            //Loading second step.
            setTimeout(() => {
                if(!props.opportunities){
                    setAskBrand(true);
                    setIsSubmitting(false);
                    
                    props.setModalProps({
                        ...props.modalProps,
                        title: 'O que você gostaria de pedir para a marca?',
                        itemId: props.itemId,
                        brandId: props.brandId,
                        step: 2,
                    })
                }

                setILoaderSucessStep1(true);
            }, 500);
        }
    }

    function handleClose(){
        setAskBrand(false);
        setIsSubmittingAskBrand(false);
        setILoaderSucess(false);
        setIHideStepOne(false);

        if(props.onManualCancel.fnc){
            props.onManualCancel.fnc();
        }

        if(props.returnTo){
            history.push(props.returnTo);
        } else if(!props.returnToHome){
            props.setBuyerModalStatus(false);
            if(props.closeBuyerModal){
                props.closeBuyerModal.fnc();
            }
        } else {
            history.push("/marcas");
        }
    }

    function handleManualConfirm(){
        if(props.onManualConfirm.fnc){
            props.onManualConfirm.fnc();
            return props.setBuyerModalStatus(false);
        } else {
            return props.setBuyerModalStatus(false);
        }
    }

    function handleManualCancel(){
        //reset first step.
        if(!props.opportunities){
            setAskBrand(false);
        }

        if(props.onManualCancel.fnc){
            props.onManualCancel.fnc();
        } else {
            props.setBuyerModalStatus(false);
        }
    }

    const onSubmitAskBrand = (data) => {
        //hide second step.
        setAskBrand(false);
        setIsSubmittingAskBrand(true);
        setIHideStepOne(true);
        setILoaderSucessStep1(false);




        data.brandId = props.brandId;
        data.itemId = props.itemId;

        if (data.order === '4') {
            setTimeout(() => {    
                if (!Array.isArray(props.user.company) && props.user.company.addresses.length > 0) {
                            if(props.user.company.addresses[0].cep == '' && props.user.company.addresses[0].city.desc == '' && props.user.company.addresses[0].city.state.desc == '') {
                                props.setBuyerModalStatus(false);
                                setIsSubmittingAskBrand(false);
                                setModalStatusAddress(true);
                            } else {
                                Services.postSendOrder(data, props.user.token, (status, response) => {
                                  //  console.log('getWhatsapp----------->', status, response)
                                    if (status === 200) {
                                        //Sucess Message.
                                        setTimeout(() => {
                                            setILoaderSucess(true);
                                            if(props.updateStatusContact){
                                                props.updateStatusContact(props.itemId)
                                            }
                                        }, 1000);
                                    }
                                });
                            }
                } else {
                    props.setBuyerModalStatus(false);
                    setIsSubmittingAskBrand(false);
                    setModalPropsAddress({
                        ...modalPropsAddress,
                        data: data
                    });
                    setModalStatusAddress(true);
                }
            }, 1500);
        } else {
            Services.postSendOrder(data, props.user.token, (status, response) => {
                console.log('getWhatsapp----------->', status, response)
                if (status === 200) {
                    //Sucess Message.
                    setTimeout(() => {
                        setILoaderSucess(true);
                        if(props.updateStatusContact){
                            props.updateStatusContact(data.itemId)
                        }
                    }, 1000);
                }
            });
        }
    }

    return (
        <React.Fragment>
            <ModalAddress
                buyerModalStatus={modalStatusAddress}
                {...modalPropsAddress}
            />

            <div id="BuyerModal" style={{display: props.buyerModalStatus ? 'flex' : 'none'}}>
                <CSSTransition
                    in={props.buyerModalStatus}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                >
                    <div id="BuyerModal__container">
                        <div id="BuyerModal__header">
                            <div id="BuyerModal__container--title" className={!props.title ? 'BuyerModal--noPadding' : ''}>
                                <span dangerouslySetInnerHTML={{__html: !loaderSucess ? props.title : ' &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mensagem enviada com sucesso!' }}>
                                </span>
                            </div>
                            <div id="BuyerModal__container--close">
                                <CloseIcon onClick={handleClose}/>
                            </div>
                        </div>
                        <div id="BuyerModal__container--desc" className={props.buyerModalAlign === "center" ? "BuyerModal__container--desc-center" : ""}>
                            { isSubmitting ?
                                <React.Fragment>
                                    { props.opportunities ? 
                                        <React.Fragment>
                                            { !loaderSucessStep1 && <LoaderForButton /> }
                                            { loaderSucessStep1 && 
                                                <React.Fragment>
                                                    <span className="modal__whatsapp--sucess"> Sua solicitação foi enviada com sucesso!</span> 
                                                    <button className="secondBtn secondBtn__confirm" onClick={handleClose}>OK</button>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                        :   <React.Fragment>
                                                <LoaderForButton />
                                            </React.Fragment>
                                    }
                                </React.Fragment>
                        
                                :
                                <React.Fragment>
                                    {!hideStepOne && props.step === 1 &&
                                        <div className="modal__whatsapp" style={ !askBrand ? {} : { 'display':'none' }}>
                                            <span className="modal__whatsapp--description">Os contatos com as marcas interessadas na sua empresa podem ser feitos diretamente pelo seu WhatsApp. Não se preocupe, o seu contato será exibido somente para as marcas que você autorizar.</span>
                                            <span className="modal__whatsapp--description">Você deseja receber seus contatos pelo WhatsApp?</span>

                                            <form id="form" onSubmit={handleSubmit(onSubmit)}>
                                                <fieldset className="form__group">
                                                    <input type="radio" name="sitActive" onChange={e => {handleSitActive(true)}} defaultValue="true" id="accept" ref={register({ required: true })} />
                                                    <label htmlFor="accept">Eu desejo receber contatos de marcas pelo meu WhatsApp:</label>

                                                    <InputMask mask="(99) 99999-9999" onChange={e => {setNumberValue(e.target.value)}} type="text" name="number" id="number" placeholder="(11) 99999-9999" ref={register({ required: true })} style={ !sitActive ? { 'pointerEvents':'none' } : {}}/>
                                                    {sitActive && numberValue == '' && <span className="required">é necessário preencher seu telefone*</span>}
                                                </fieldset>
                                                <fieldset className="form__group">
                                                    <input type="radio" name="sitActive" onChange={e => {handleSitActive(false);}} defaultValue="false" id="reject" ref={register({ required: true })} />
                                                    <label htmlFor="reject">Eu não desejo usar meu WhatsApp para comunicação com as marcas.</label>
                                                </fieldset>
                                                <fieldset className="form__group buttons">
                                                    <input type="button" className="firstBtn" value="Cancelar" onClick={props.onCancel ? props.onCancel : handleManualCancel} />
                                                    <input type="submit" className="secondBtn" value={ props.opportunities ? 'Salvar' : 'Salvar e Continuar'} />
                                                </fieldset>
                                            </form>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                            { isSubmittingAskBrand ? 
                                <React.Fragment>
                                    { !loaderSucess && <LoaderForButton />}
                                    {   
                                        loaderSucess &&
                                        <React.Fragment>
                                            <span className="modal__whatsapp--sucess"> Sua mensagem foi enviada para <strong>{props.brandName}</strong> com sucesso! </span>
                                            <button className="secondBtn secondBtn__confirm" onClick={handleClose}>OK</button>
                                        </React.Fragment>
                                    }
                                    
                                </React.Fragment>
                                :
                                props.deliver !== false ? 
                                    <React.Fragment>
                                        {!loaderSucess && props.step === 2 &&
                                            <div className="modal__whatsapp modal__askBrand" style={ askBrand || props.step === 2 ? {} : { 'display':'none' }}>
                                                <form id="form__askBrand" onSubmit={handleSubmit2(onSubmitAskBrand)}>
                                                    <fieldset className="form__group">
                                                        <input type="radio" name="order" defaultValue="1" id="comercial" ref={register2({ required: true })} />
                                                        <label htmlFor="comercial">Solicitar apresentação comercial</label>
                                                    </fieldset>
                                                    <fieldset className="form__group">
                                                        <input type="radio" name="order" defaultValue="2" id="tabela_precos" ref={register2({ required: true })} />
                                                        <label htmlFor="tabela_precos">Solicitar tabela de preços</label>
                                                    </fieldset>
                                                    <fieldset className="form__group">
                                                        <input type="radio" name="order" defaultValue="3" id="sobre_produto" ref={register2({ required: true })} />
                                                        <label htmlFor="sobre_produto">Solicitar mais informações sobre o produto</label>
                                                    </fieldset>
                                                    <fieldset className="form__group">
                                                        <input type="radio" name="order" defaultValue="4" id="solicitar_amostra" ref={register2({ required: true })} />
                                                        <label htmlFor="solicitar_amostra">Solicitar amostra</label>
                                                    </fieldset>
                                                    <fieldset className="form__group">
                                                        <textarea name="message" id="message" placeholder="Comentários adicionais" cols="30" rows="10" ref={register2}></textarea>
                                                    </fieldset>
                                                    <fieldset className="form__group buttons">
                                                        <input type="button" className="firstBtn" value="Cancelar" onClick={props.onCancel ? props.onCancel : handleManualCancel} />
                                                        <input type="submit" className="secondBtn" value="Enviar Solicitação" />
                                                    </fieldset>
                                                </form>
                                            </div>
                                        }
                                    </React.Fragment>
                                : 
                                <>
                                <div className="modal__whatsapp modal__askBrand">
                                               <form id="form__askBrand" onSubmit={handleSubmit2(onSubmitAskBrand)}>
                                                   <p style={{color: '#eb1b2f'}} >Identificamos que a Marca não trabalha em sua região. <br/>
                                                   Caso queira saber se amarca teria interesse em trabalhar com você, <br/>
                                                   mande uma mensagem.
                                                   </p>
                                                   <fieldset className="form__group">
                                                       <input type="radio" name="order" checked defaultValue="3" id="sobre_produto" ref={register2({ required: true })} />
                                                       <label htmlFor="sobre_produto">Solicitar mais informações sobre o produto</label>
                                                   </fieldset>
                                                   <fieldset className="form__group">
                                                       <textarea name="message" id="message" placeholder="Comentários adicionais" cols="30" rows="10" ref={register2}></textarea>
                                                   </fieldset>
                                                   <fieldset className="form__group buttons">
                                                       <input type="button" className="firstBtn" value="Cancelar" onClick={props.onCancel ? props.onCancel : handleManualCancel} />
                                                       <input type="submit" className="secondBtn" value="Enviar Solicitação" />
                                                   </fieldset>
                                               </form>
                                           </div>
                               </>
                            }

                           
                        </div>
                        {
                            props.buttons &&
                                (
                                    props.firstBtnName
                                        ? <div id="BuyerModal__buttons" className={props.reverseButtons ? "BuyerModal__buttons--reverse" : ""}>
                                            <button className="firstBtn" onClick={props.onConfirm ? props.onConfirm : handleManualConfirm}>
                                                {props.firstBtnName}
                                            </button>
                                            {
                                                props.secondBtnName &&
                                                    <button className="secondBtn" onClick={props.onCancel ? props.onCancel : handleManualCancel}>
                                                        {props.secondBtnName}
                                                    </button>
                                            }
                                        </div>
                                        : <div id="BuyerModal__buttons" className={props.reverseButtons ? "BuyerModal__buttons--reverse" : ""}>
                                            <button className="firstBtn" onClick={props.onConfirm ? props.onConfirm : handleManualConfirm}>
                                                {
                                                    props.buyerModalExit ? 'Sair sem salvar' : 'Confirmar'
                                                }
                                            </button>
                                            <button className="secondBtn" onClick={props.onCancel ? props.onCancel : handleManualCancel}>
                                                {
                                                    props.buyerModalExit ? 'Voltar' : 'Cancelar'
                                                }
                                            </button>
                                        </div>
                                )
                        }
                    </div>
                </CSSTransition>
            </div>
        </React.Fragment>
    )
}

export default Connect()(ModalWhatsApp);
