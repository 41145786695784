import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Product.scss';
import { ReactComponent as FavorIcon } from "../../../../../images/buyers/home/products/favor.svg";
import { Link, useHistory } from 'react-router-dom';
// import RequestSampleModal from "../../BuyerItemView/RequestSampleModal/RequestSampleModal";
import Services from "../../../../../services/services";
import Connect from "../../../../../store/config/connect";
import moment from 'moment-timezone';
import { slugify } from '../../../../Utils/helper';
import { ReactComponent as InformationContact } from "../../../../../images/buyers/home/products/information_contact.svg";
import ModalWhatsApp from '../../../../ModalWhatsApp/ModalWhatsApp'
import CheckedIcon from './../../../../Buyers/CheckedIcon/CheckedIcon'
import getModalTitle from '../../../Utils/Modal/ModalNames'

const Product = props => {
    const history = useHistory();
    const [user, setUser] = useState(undefined);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalProps, setModalProps] = useState({
        setBuyerModalStatus: setModalStatus,
        title: 'WhatsApp',
        buyerModalDesc: ``,
        returnTo: false,
        buttons: false,
        onManualConfirm: false,
        user: props.user,
        onManualCancel: false
    });


    var favoriteItem = (item, user) => {
        if (!item) {
            return;
        }

        async function fetchItem() {
            Services.postFavoriteItem(item.id, user.token, (status, response) => {
                if (status === 200) {
                    if (response.success) {
                        if (props.type === 'favoritos') {
                            window.location.reload();
                        }
                        setProduct({
                            ...product,
                            sit_favorited: !product.sit_favorited
                        });
                    } else {
                        window.location.reload();
                    }
                    return;
                }
            });
        }

        fetchItem();
    }

    const UpdateItemStatusContact = () => {
        setProduct({
            ...product,
            sit_sample: 1
        });
    }

    const handleRequestSample = (product) => {
        Services.getWhatsapp('', props.user.token, (status, data) => {
            console.log('ja tem numero cadastrado', data, data === null);
            if (data === null) {
                setModalProps({
                    ...modalProps,
                    setModalProps: setModalProps,
                    modalProps: modalProps,
                    itemId: product.id,
                    brandId: props.brand.companies[0].id,
                    step: 1
                });
                setModalStatus(true);
            } else {
                //sets up second modal, but has verify register.
                setModalProps({
                    ...modalProps,
                    step: 2,
                    title: getModalTitle(product.sit_sample, product.sit_region_covared),
                    itemId: product.id,
                    brandId: props.brand.companies[0].id,
                    brandName: props.brand.companies && props.brand.companies[0].name,
                    deliver: product.sit_region_covared
                });

                //Open modal
                setModalStatus(true);
            }
        });
    }


    useEffect(() => {
        if (props.user.token && !user) {
            setUser(props.user);
        }
    }, [props.user, user]);

    const [product, setProduct] = useState(props.product);

    useEffect(() => {
        loadStates();
    }, []);

    const [states, setStates] = useState(null);

    const loadStates = useCallback(() => {
        try {
            var data = JSON.parse(window.localStorage.getItem("estadosLista"));
            var servicesCache = moment(window.localStorage.getItem("servicesCache"), "YYYY-MM-DD HH:mm:ss");
            var shouldBustCache = servicesCache.isValid() ? !(moment(moment.now()).subtract(1, 'hour') > servicesCache) : false;
            if (data && shouldBustCache) {
                if (data.length > 0) {
                    setStates(data);
                } else {
                    throw "Objeto Vazio";
                }
            } else {
                throw "Objeto NULL";
            }
        } catch (e) {
            Services.getStates((status, resp) => {
                if (status === 200) {
                    window.localStorage.setItem("estadosLista", JSON.stringify(resp.msg));
                    setTimeout(
                        function () {
                            window.localStorage.setItem("servicesCache", moment(moment.now()).format('YYYY-MM-DD HH:mm:ss'));
                        }, 2000);
                    setStates(resp.msg);
                }
            });
        }
    }, []);


    console.log(product.sit_sample)
 
    return (
        <>
            <ModalWhatsApp
                buyerModalStatus={modalStatus}
                {...modalProps}
                updateStatusContact={UpdateItemStatusContact}
            />
            <div className="Product" key={product.id}>
                <div className="BuyerCard__product">
                    <div className="BuyerCard__product--header">
                        <div className="BuyerCard__product--brandLogo" style={{ backgroundImage: `url("${props.brand.companies && props.brand.companies[0].logo ? props.brand.companies[0].logo : null}")` }}></div>
                        <span className="BuyerCard__product--brandName">{props.brand.companies && props.brand.companies[0].name ? props.brand.companies[0].name !== '' && props.brand.companies[0].name.length >= 41 ? props.brand.companies[0].name.substr(0, 41).toLowerCase()  + '...' : props.brand.companies[0].name.toLowerCase()  : ''} {props.brand.companies[0].sit_verified ?  <CheckedIcon/> : null  }</span>
                    </div>
                    <Link to={`/varejistas/produtos/${product.id}/${encodeURIComponent(slugify(product.desc))}`} className="productContainer">

                        {
                            product.documents && product.documents.length > 0 ?
                                <div className="productLogo" style={{ backgroundImage: `url("${product.documents && product.documents.length > 0 ? product.documents[0].url : null}")` }}></div>
                                : <div className="productLogo" style={{ backgroundImage: `url("${product.photo ? product.photo : null}")` }}></div>
                        }
                        <div className="productName">
                            <span>{product.desc && product.desc !== '' ? product.desc.length >= 41 ? product.desc.substr(0, 41).toLowerCase()  + '...' : product.desc.toLowerCase()  : 'Sem descrição'}</span>


                        </div>
                        <div className="productComplement">
                            <span>{product.flavor && product.flavor !== '' ? product.flavor.length >= 41 ? product.flavor.substr(0, 41).toLowerCase()  + '... | ' : product.flavor.toLowerCase()  + ' | ' : ''}{product.size && product.size !== '' ? product.size.length > 6 ? product.size.substr(0, 6).toLowerCase() + '...' : product.size.toLowerCase() : ''}</span>

                        </div>
                    </Link>
                    <div className="actions">
                        {
                            product.sit_favorited ?
                                <div className="actions__option actions__option--active" onClick={() => { favoriteItem(product, user) }} title="Favoritar/Desfavoritar">
                                    <div className="actions__option--icon">
                                        <FavorIcon />
                                    </div>
                                    <span className="actions__option--text">Favoritado</span>
                                </div>
                                :
                                <div className="actions__option" onClick={() => { favoriteItem(product, user) }} title="Favoritar/Desfavoritar">
                                    <div className="actions__option--icon">
                                        <FavorIcon />
                                    </div>
                                    <span className="actions__option--text">Favoritar</span>
                                </div>
                        }
                        {
                            product.sit_sample ?
                                <div className="actions__option actions__option--active" onClick={() => { handleRequestSample(product) }} title="Conversa iniciada">
                                    <div className="actions__option--icon">
                                        <InformationContact />
                                    </div>
                                    <span className="actions__option--text">Conversa iniciada</span>
                                </div>
                                :
                                <div className="actions__option" onClick={() => { handleRequestSample(product) }} title="Entrar em contato">
                                    <div className="actions__option--icon">
                                        <InformationContact />
                                    </div>
                                    <span className="actions__option--text">Entrar em contato</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ auth, helper }, props) => {
    return {
        ...props,
        ...auth,
        ...helper
    };
};

export default Connect(mapStateToProps)(Product);
