export const helperStore = {
    helper: {
      isMobile: false,
      eventChecker: false,
      filterCatalog: false,
      filterFavorites: false,
      filterSamples: false,
      showModal: false,
      emailToRegister: false,
    }
  };

export const RESIZE_WINDOW = "RESIZE_WINDOW";
export const FILTER_CATALOG = "FILTER_CATALOG"; //filtro stored do Descobrir Produtos
export const FILTER_FAVORITES = "FILTER_FAVORITES"; //filtro stored do Meus Favoritos
export const FILTER_SAMPLES = "FILTER_SAMPLES"; //filtro stored do Pedidos de Amostras
export const EMAIL_TO_REGISTER = "EMAIL_TO_REGISTER"; //variável de email ao clicar em cadastrar na Home
export const SHOW_MODAL = "SHOW_MODAL";

const helperReducer = (state = helperStore, action) => {
    switch (action.type) {
      case RESIZE_WINDOW:
        return {
          helper: {
            ...state.helper,
            isMobile: action.payload,
            eventChecker: true,
          }
        };
      break;
      case FILTER_CATALOG:
        return {
          helper: {
            ...state.helper,
            filterCatalog: action.payload,
          }
        };
      break;
      case FILTER_FAVORITES:
        return {
          helper: {
            ...state.helper,
            filterFavorites: action.payload,
          }
        };
      break;
      case SHOW_MODAL:
        return {
          helper: {
            ...state.helper,
            showModal: action.payload,
          }
        };
      break;
      case FILTER_SAMPLES:
        return {
          helper: {
            ...state.helper,
            filterSamples: action.payload,
          }
        };
      break;
      case EMAIL_TO_REGISTER:
        return {
          helper: {
            ...state.helper,
            emailToRegister: action.payload,
          }
        };
      break;
      default:
        return state;
    }
};

export const SetEmailToRegister = email => {
  return {
    type: EMAIL_TO_REGISTER,
    payload: email
  };
};

export const FilterCatalog = payloadFilter => {
  return {
    type: FILTER_CATALOG,
    payload: payloadFilter
  };
};

export const FilterFavorites = payloadFilter => {
  return {
    type: FILTER_FAVORITES,
    payload: payloadFilter
  };
};

export const ShowModal = showModal => {
  return {
    type: SHOW_MODAL,
    payload: showModal
  };
};

export const FilterSamples = payloadFilter => {
  return {
    type: FILTER_SAMPLES,
    payload: payloadFilter
  };
};

export const ResizeWindow = isMobile => {
  return {
    type: RESIZE_WINDOW,
    payload: isMobile
  };
};

export default helperReducer;