import { useState, useEffect } from "react";
import Connect from "../../store/config/connect";
import { useLocation, useHistory } from 'react-router-dom';
import services from "../../services/services";

const SendRD = props => {
    const { pathname, search } = useLocation();

    const views = [
        "comunidade",
        "servicos",
        "academia",
        "varejistas",
        "trade-marketing",
        "cadastro-no-varejo",
        "selos",
        "loja-online",
        "/juridico/sfera",
        "rg-nutri",
        "mentoria",
        "produtos",
    ]

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("localev2-user"));
        const regex = /\d+$/;
        const isValid = views.includes(pathname.replace("/marcas/", "")) || regex.test(pathname.replace("/marcas/varejistas/compradores/", ""));

        if (user?.company?.sit_brand && isValid) {

            const origin = pathname.includes("varejistas") ? "varejistas" : pathname.replace("/marcas/", "")

            const payload = {
                origin: `${search == "?button" ? "home " : ""}${origin}`,
                pathname: `https://www.locale.com.br${pathname}`
            }

            services.postTrackRdStation(payload, user.token, (status, data) => {
            });

            if (search == "?button") {
                window.history.replaceState(null, null, pathname)
            }
        }
    }, [pathname]);

    return null;
}

const mapStateToProps = ({ auth }, props) => {
    return {
        auth,
        ...props
    };
};

export default Connect(mapStateToProps)(SendRD);
