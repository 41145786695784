import { useState, useEffect, useCallback } from "react";
import { UpdatePremium } from '../../store/premium';
import Services from '../../services/services';
import Connect from "../../store/config/connect";
import { useHistory, useLocation } from 'react-router-dom';

const VerifyPremium = props => {
  const [history] = useState(useHistory());
  const { pathname } = useLocation();
  const [user, setUser] = useState(null);
  // const [userCount, setCountUser] = useState(0);

  const getPremium = useCallback((payload, token) => {
    return Services.postGetPremiumValidation(payload, token, (status, response) => {
      if(status === 200){
        var premium = {
          isPremium: response.msg.validate.sit_active === 1 ? true : false,
          premiumActivationDate: response.msg.validate.updated_date,
          premiumCount: response.msg.usageCount,
        };

        props.dispatch(UpdatePremium(premium));
      }
    });
  }, []);

  useEffect(() => {
    if(props.user.token){
      setUser(props.user);
      const payload = {
        brandId: props.user.company.id
      };

      getPremium(payload, props.user.token);
    }
  }, [props.user, history, pathname]);

  return null;
}

const mapStateToProps = ({ auth, premium }) => {
  return {
    ...premium,
    ...auth
  };
};

export default Connect(mapStateToProps)(VerifyPremium);
