import React, { Suspense, lazy, useEffect } from 'react';
import './router.css';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import { BrowserRouter, Route, Switch, useLocation, Redirect } from 'react-router-dom';
import ScrollToTop from '../components/Utils/ScrollToTop';
import VerifyAuth from '../components/Utils/VerifyAuth';
import VerifyPremium from '../components/Utils/VerifyPremium';
import { LastLocationProvider } from 'react-router-last-location';
import SendGA from '../components/Utils/SendGA';
import SendTracking from '../components/Utils/SendTracking';
import SendRD from '../components/Utils/SendRD';
import SetFavicon from '../components/Utils/SetFavicon';
import Connect from '../store/config/connect';
import BuyerBrandProducts from '../components/Buyers/BuyerItemView/BuyerBrandProducts/BuyerBrandProducts';

const BrandRegistration = lazy(() => import('../components/Brands/BrandRegistration/BrandRegistration'));
const BuyerRegistration = lazy(() => import('../components/Buyers/BuyerRegistration/BuyerRegistration'));
const ContentAcademy = lazy(() => import('../components/Brands/BrandAcademy/ContentAcademy/ContentAcademy'));
const BrandHome = lazy(() => import('../components/Brands/BrandHome/BrandHome'));
const BrandExpert = lazy(() => import('../components/Brands/BrandExpert/BrandExpert'));
const BrandEdit = lazy(() => import('../components/Brands/BrandEdit/BrandEdit'));
const BrandCommunity = lazy(() => import('../components/Brands/BrandCommunity/BrandCommunity'));
const BrandResearch = lazy(() => import('../components/Brands/BrandResearch/BrandResearch'));
const BrandConsultancy = lazy(() => import('../components/Brands/BrandConsultancy/BrandConsultancy'));
const BrandAcademy = lazy(() => import('../components/Brands/BrandAcademy/BrandAcademy'));
const AcademyTrailContent = lazy(() => import('../components/Brands/BrandAcademy/TrailContentAcademy/TrailContentAcademy'));
const Manifest = lazy(() => import('../components/Home/Manifest/Manifest'));
const BrandRules = lazy(() => import('../components/Brands/BrandRules/BrandRules'));
const BrandTrade = lazy(() => import('../components/Brands/BrandTrade/BrandTrade'));
const BrandSimplus = lazy(() => import('../components/Brands/BrandSimplus/BrandSimplus'));
const BrandGpa = lazy(() => import('../components/Brands/BrandGpa/BrandGpa'));
const BrandSfera = lazy(() => import('../components/Brands/BrandSfera/BrandSfera'));
const BrandOlist = lazy(() => import('../components/Brands/BrandOlist/BrandOlist'));
const BrandIndicator = lazy(() => import('../components/Brands/BrandIndicator/BrandIndicator'));
const BrandProfile = lazy(() => import('../components/Brands/BrandProfile/BrandProfile'));
const CreateProduct = lazy(() => import('../components/Brands/CreateProduct/CreateProduct'));
const BrandProducts = lazy(() => import('../components/Brands/BrandProducts/BrandProducts'));
const BrandAgreement = lazy(() => import('../components/Brands/BrandAgreement/BrandAgreement'));
const BrandItemView = lazy(() => import('../components/Brands/BrandItemView/BrandItemView'));
const EditProduct = lazy(() => import('../components/Brands/EditProduct/EditProduct'));
const CreatePassword = lazy(() => import('../components/Brands/Boost/CreatePassword/CreatePassword'));
const DeleteAccount = lazy(() => import('../components/Brands/Boost/DeleteAccount/DeleteAccount'));
const BrandChat = lazy(() => import('../components/Brands/BrandChat/BrandChat'));
const BrandBuyersList = lazy(() => import('../components/Brands/BrandBuyersList/BrandBuyersList'));

const BrandBuyerRate = lazy(() => import('../components/Brands/BrandBuyersList/BrandRateBuyer/BrandBuyerRate'));
const BrandBuyerAbuse = lazy(() => import('../components/Brands/BrandBuyersList/BrandRateBuyerAbuse/BrandBuyerAbuse'));


const BuyerUserList = lazy(() => import('../components/Brands/BrandBuyersList/BuyerUserList/BuyerUserList'));
const MyContacts = lazy(() => import('../components/Brands/MyContacts/MyContacts'));
const MyContactsList = lazy(() => import('../components/Brands/MyContacts/MyContactsList/MyContactsList'));
const BrandServices = lazy(() => import('../components/Brands/BrandServices/BrandServices'));
const BrandLabel = lazy(() => import('../components/Brands/BrandLabel/BrandLabel'));
const BrandOnlineStore = lazy(() => import('../components/Brands/BrandOnlineStore/BrandOnlineStore'));
const BrandLogistic = lazy(() => import('../components/Brands/BrandLogistic/BrandLogistic'));
const About = lazy(() => import('../components/Home/About/About'));
const Media = lazy(() => import('../components/Home/MediaPage/MediaPage'));

const Consumer = lazy(() => import('../components/Home/Consumer/Consumer'));
const notFound = lazy(() => import('../components/Home/404/notFound404'));


const Brand = lazy(() => import('../components/Home/Brand/Brand'));
const MarketPlace = lazy(() => import('../components/Home/MarketPlace/MarketPlace'));
const SpecialAgreement = lazy(() => import('../components/Home/SpecialAgreement/SpecialAgreement'));
const Partnership = lazy(() => import('../components/Home/Partnership/Partnership'));
const Home = lazy(() => import('../components/Home/Index/Home'));
const Login = lazy(() => import('../components/Home/Login/Login'));
const Premium = lazy(() => import('../components/Home/Premium/Premium'));
const PasswordRecovery = lazy(() => import('../components/Home/Login/PasswordRecovery/PasswordRecovery'));
const CreatePasswordRecovery = lazy(() => import('../components/Home/Login/CreatePasswordRecovery/CreatePasswordRecovery'));
const BrandsFaq = lazy(() => import('../components/Home/BrandsFaq/BrandsFaq'));
const BrandsTerms = lazy(() => import('../components/Home/BrandsTerms/BrandsTerms'));
const BuyersTerms = lazy(() => import('../components/Home/BuyersTerms/BuyersTerms'));
const BuyerChat = lazy(() => import('../components/Buyers//BuyerChat/BuyerChat'));
const BuyerHome = lazy(() => import('../components/Buyers/BuyerHome/BuyerHome'));
const BuyerProductCatalog = lazy(() => import('../components/Buyers/BuyerProductCatalog/BuyerProductCatalog'));
const BuyerProfile = lazy(() => import('../components/Buyers/BuyerProfile/BuyerProfile'));
const BuyerSupport = lazy(() => import('../components/Buyers/BuyerSupport/BuyerSupport'));
const BuyerItemView = lazy(() => import('../components/Buyers/BuyerItemView/BuyerItemView'));
const BuyerFavoritesCatalog = lazy(() => import('../components/Buyers/BuyerProductCatalog/BuyerFavoritesCatalog'));
const BuyerSamplesCatalog = lazy(() => import('../components/Buyers/BuyerProductCatalog/BuyerSamplesCatalog'));
const BuyerTendencies = lazy(() => import('../components/Buyers/BuyerTendencies/BuyerTendencies'));
const BuyerTendenciesDetail = lazy(() => import('../components/Buyers/BuyerTendenciesDetail/BuyerTendenciesDetail'));
const BuyerOpportunities = lazy(() => import('../components/Buyers/BuyerOpportunities/BuyerOpportunities'));
const BuyerOpportunitiesDecision = lazy(() => import('../components/Buyers/BuyerOpportunitiesDecision/BuyerOpportunitiesDecision'));


const myRouter = props => {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <SendGA />
        <SendTracking />
        <SendRD />
        <ScrollToTop />
        <VerifyAuth />
        {/* <VerifyDevice /> */}
        <VerifyPremium />
        <Switch>
          <AnimationRoute />
        </Switch>
      </LastLocationProvider>
    </BrowserRouter>
  );
}

const PrivateBrandRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    const user = window.localStorage.getItem("localev2-user")
      ? JSON.parse(window.localStorage.getItem("localev2-user"))
      : "";
    if (user) {
      if (user.company.sit_buyer || !user.company.sit_brand) {
        window.localStorage.removeItem("localev2-user");
        window.location.href = "/login";
      }
    } else {
      window.localStorage.removeItem("localev2-user");
      window.location.href = "/login";
    }

    //se o usuário está bloqueado, ele não poderá sair da home de marcas
    if (user && parseInt(user.company.sit_blocked) === 1 && props.location.pathname !== "/marcas") {
      window.location.href = "/marcas";
    }

    return user
      ? <Component {...props} />
      : window.location.href = "/login"
  }} />
);

const PrivateBuyerRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    const user = window.localStorage.getItem("localev2-user")
      ? JSON.parse(window.localStorage.getItem("localev2-user"))
      : "";
    if (user) {
      if (user.company.sit_brand || !user.company.sit_buyer) {
        window.localStorage.removeItem("localev2-user");
        window.location.href = "/login";
      }
    } else {
      window.localStorage.removeItem("localev2-user");
      window.location.href = "/login";
    }

    return user
      ? <Component {...props} />
      : window.location.href = "/login";
  }} />
);

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

function AnimationRoute() {
  let location = useLocation();

  return (
    <TransitionGroup className="transition-group">
      <SetFavicon />
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={500}
      >
        <section className="route-section">
          <Suspense fallback={<div></div>}>
            <Switch location={location}>
              <Route path="/" exact component={Home} />
              <Route path="/login" exact render={() => <ExternalRedirect to="https://go.hotmart.com/B92697715U?ap=5600" />} />
              <Route path="/recuperar-senha" exact render={() => <ExternalRedirect to="https://go.hotmart.com/B92697715U?ap=5600" />} />
              <Route path="/marcas" exact render={() => <ExternalRedirect to="https://go.hotmart.com/B92697715U?ap=5600" />} />
              {/* <Route path="/nova-senha/:hash" exact component={CreatePasswordRecovery} />
              <Route path="/sobre" exact component={About} />
              <Route path="/na-midia" exact component={Media} />
              <Route path="/home-consumidores" exact component={Consumer} />
              <Route path="/manifesto" exact component={Manifest} />
              <Route path="/marketplace" exact component={MarketPlace} />
              <Route path="/specialAgreement" exact component={SpecialAgreement} />
              <Route path="/partnership" exact component={Partnership} />
              <Route path="/premium" exact component={Premium} />
              <Route path="/faq-marcas" exact component={BrandsFaq} />
              <Route path="/termos-de-uso-marcas" exact component={BrandsTerms} />
              <Route path="/termos-de-uso-varejistas" exact component={BuyersTerms} />
              <Route path="/cadastro-marcas" exact component={BrandRegistration} />
              <Route path="/cadastro-marcas-boost" exact component={BrandRegistration} />
              <Route path="/boost/criar-senha/:hash" exact component={CreatePassword} />
              <Route path="/boost/deletar-conta/:hash" exact component={DeleteAccount} />
              <Route path="/cadastro-varejistas" exact component={BuyerRegistration} /> */}

              {/* <PrivateBrandRoute path="/marcas" exact component={BrandHome} />
              <PrivateBrandRoute path="/marcas/editar" exact component={BrandEdit} />
              <PrivateBrandRoute path="/marcas/meu-perfil/:type" exact component={BrandProfile} />
              <PrivateBrandRoute path="/marcas/criar-produto" exact component={CreateProduct} />
              <PrivateBrandRoute path="/marcas/produtos" exact component={BrandProducts} />
              <PrivateBrandRoute path="/marcas/marketplaces" exact component={BrandAgreement} />
              <PrivateBrandRoute path="/marcas/inteligencia-de-mercado" exact component={BrandResearch} />
              <PrivateBrandRoute path="/marcas/expert" exact component={BrandExpert} />
              <PrivateBrandRoute path="/marcas/consultoria" exact component={BrandConsultancy} />
              <PrivateBrandRoute path="/marcas/academia" exact component={BrandAcademy} />

              <PrivateBrandRoute path="/marcas/academia/trilha/:id" exact component={AcademyTrailContent} />
              <PrivateBrandRoute path="/marcas/regras-comunidade" exact component={BrandRules} />
              <PrivateBrandRoute path="/marcas/gpa" exact component={BrandGpa} />
              <PrivateBrandRoute path="/juridico/sfera" exact component={BrandSfera} />
              <PrivateBrandRoute path="/marcas/indicadores" exact component={BrandIndicator} />
              <PrivateBrandRoute path="/marcas/olist" exact component={BrandOlist} />
              <PrivateBrandRoute path="/marcas/trade-marketing" exact component={BrandTrade} />
              <PrivateBrandRoute path="/marcas/cadastro-no-varejo" exact component={BrandSimplus} />
              <PrivateBrandRoute path="/marcas/produto/:itemId" exact component={BrandItemView} />
              <PrivateBrandRoute path="/marcas/produto/:id/editar" exact component={EditProduct} />
              <PrivateBrandRoute path="/marcas/comunidade" exact component={BrandCommunity} />
              <PrivateBrandRoute path="/marcas/mensagens" exact component={BrandChat} />
              <PrivateBrandRoute path="/marcas/varejistas" exact component={BrandBuyersList} />
              <PrivateBrandRoute path="/marcas/varejistas/compradores/:buyerCnpj" exact component={BuyerUserList} />
              <PrivateBrandRoute path="/marcas/academia/conteudo/:id" exact component={ContentAcademy} />
              <PrivateBrandRoute path="/marcas/varejistas/avaliacao" exact component={BrandBuyerRate} />
              <PrivateBrandRoute path="/marcas/varejistas/denuncia" exact component={BrandBuyerAbuse} />
              <PrivateBrandRoute path="/marcas/minhas-oportunidades" exact component={MyContacts} />
              <PrivateBrandRoute path="/marcas/meus-contatos/compradores/:buyerCnpj" exact component={MyContactsList} />
              <PrivateBrandRoute path="/marcas/servicos" exact component={BrandServices} />
              <PrivateBrandRoute path="/marcas/selos" exact component={BrandLabel} />
              <PrivateBrandRoute path="/marcas/loja-online" exact component={BrandOnlineStore} />
              <PrivateBrandRoute path="/marcas/logistica" exact component={BrandLogistic} /> */}

              {/* <PrivateBuyerRoute path="/varejistas" exact component={BuyerHome} />
              <PrivateBuyerRoute path="/varejistas/meu-perfil/:type" exact component={BuyerProfile} />
              <PrivateBuyerRoute path="/varejistas/produtos" exact component={BuyerProductCatalog} />
              <PrivateBuyerRoute path="/varejistas/favoritos" exact component={BuyerFavoritesCatalog} />
              <PrivateBuyerRoute path="/varejistas/amostras" exact component={BuyerSamplesCatalog} />
              <PrivateBuyerRoute path="/varejistas/mensagens" exact component={BuyerChat} />
              <PrivateBuyerRoute path="/varejistas/suporte" exact component={BuyerSupport} />
              <PrivateBuyerRoute path="/varejistas/produtos/:itemId/:slug" exact component={BuyerItemView} />
              <PrivateBuyerRoute path="/varejistas/marcas/:companyId/produtos" exact component={BuyerBrandProducts} />
              <PrivateBuyerRoute path="/varejistas/tendencias" exact component={BuyerTendencies} />
              <PrivateBuyerRoute path="/varejistas/tendencias/:id" exact component={BuyerTendenciesDetail} />
              <PrivateBuyerRoute path="/varejistas/minhas-oportunidades" exact component={BuyerOpportunities} />
              <PrivateBuyerRoute path="/varejistas/oportunidade/:brandid/:option" exact component={BuyerOpportunitiesDecision} /> */}
              <Route exact component={notFound} />

            </Switch>
          </Suspense>
        </section>
      </CSSTransition>
    </TransitionGroup>
  );
}

const mapStateToProps = ({ auth }, props) => {
  return {
    ...auth,
    ...props
  };
};


export default Connect(mapStateToProps)(myRouter);
