import { NavLink, Link, useLocation } from 'react-router-dom';
import React, { useRef, useState, useEffect } from "react";
import "./BuyerHeader.scss";
import { LogOutUser } from '../../../store/auth';
import { ReactComponent as Logo } from "../../../images/logo.svg";
import { ReactComponent as MenuIcon } from "../../../images/menu.svg";
import { ReactComponent as BellIcon } from "../../../images/bell.svg";
import { ReactComponent as NotificationsUnreadIcon } from "../../../images/notificationsUnread.svg";
import { ReactComponent as NotificationsIcon } from "../../../images/nottifications.svg";
import { ReactComponent as UserPerfil } from "../../../images/user_perfil_buyer.svg";
import { ReactComponent as ArrowDownPerfil } from "../../../images/arrow_down_perfil.svg";
import { ReactComponent  as LocaleLogo } from "../../../images/logo_locale.svg";
import Connect from '../../../store/config/connect';
import { useCallback } from 'react';
import services from '../../../services/services';

const BuyerHeader = props => {
    const mobileMenuShadow = useRef(null);

    const [user, setUser] = useState(null);
    useEffect(() => {
        if(!user && props.user && props.user.token){
            setUser(props.user);
            fetchUnreadMsgs(props.user.token);
        }
    }, [props, user]);

    const [menuUserOpen, setMenuUserOpen] = useState(false);
    const [unreadMsg, setUnreadMsg] = useState(false);
    const { pathname } = useLocation();

    const fetchUnreadMsgs = useCallback((token) => {
        setInterval(() => {
            services.getUnreadMsgs(token, (status, resp) => {
                if(status === 200){
                    setUnreadMsg(resp.msg > 0 ? true : false);
                } else {
                    setUnreadMsg(false);
                }
            })
        }, 30000)
    }, []);

    const clearCache = () => {
        window.localStorage.removeItem('productListCache');
    }

    const handleLogout = () => {
        props.dispatch(LogOutUser());
        window.location.href = "/";
    }

    return (
        user &&
            <div id="BuyerHeader">
                <div className="header__bg">
                    <div className="BuyerHeader__container container">
                        <div className="header">
                            <div className="header__button" onClick={() => {props.handleMobileMenuOpen(true)}}>
                                <MenuIcon className="header__button--icon" alt="Menu"/>
                            </div>
                            <Link to="/varejistas" className="header__logo">
                                <Logo className="header__logo--icon" alt="Local.e"/>
                            </Link>
                            <div className="nav__items">
                                {/* <div className="header__item header__item--first">
                                    <BellIcon className="header__item--icon" alt="Notificações"/>
                                    <span className="header__item--text">Notificações</span>
                                </div>*/}
                                <div className="header__item header__item--desktop" onClick={() => { setMenuUserOpen(!menuUserOpen) }}>
                                    <UserPerfil className="header__item--icon" alt="Perfil"/>
                                    <span className="header__item--text">{user.name}</span>
                                    <ArrowDownPerfil className="header__item--arrow"/>
                                </div>
                                <div className={!menuUserOpen ? "nav__dropdown nav__dropdown--closed" : "nav__dropdown"}>
                                    <div className="nav__dropdown--item">
                                        <Link to="/varejistas/meu-perfil/meus-dados">Meu perfil</Link>
                                    </div>
                                    <div className="nav__dropdown--item">
                                        <Link to="/" onClick={handleLogout}>Logout</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container--shadow">
                    <div className="menu">
                        <div className="menu__nav">
                            <NavLink to="/varejistas" exact className="menu__item" activeClassName="menu__item--active">
                                <span className="menu__item--title">Início</span>
                            </NavLink>
                             <NavLink to="/varejistas/tendencias" className="menu__item" activeClassName="menu__item--active">
                                <span className="menu__item--title">Tendências</span>
                            </NavLink>
                            <NavLink to="/varejistas/produtos" className="menu__item" onClick={clearCache} activeClassName="menu__item--active">
                                <span className="menu__item--title">Descobrir Produtos</span>
                            </NavLink>
                            <NavLink to="/varejistas/favoritos" className="menu__item" activeClassName="menu__item--active" onClick={clearCache} isActive={() => ['/varejistas/favoritos', '/varejistas/amostras'].includes(pathname)}>
                                <span className="menu__item--title">Meus Produtos</span>
                            </NavLink>
                            {/* <NavLink to="/varejistas/minhas-oportunidades" className="menu__item" activeClassName="menu__item--active">
                                <span className="menu__item--title">Minhas Oportunidades</span>
                            </NavLink> */}
                            <div className="menu__item menu__item--msg">
                                <Link to="/varejistas/mensagens">
                                    {
                                        unreadMsg 
                                            ? <NotificationsUnreadIcon className="header__item--icon"  alt="Há mensagens pendentes"/>
                                            : <NotificationsIcon className="header__item--icon" alt="Mensagens"/>
                                    }
                                    <span>Mensagens</span>
                                </Link>
                            </div>
                            <NavLink to="/varejistas/suporte" exact className="menu__item" activeClassName="menu__item--active">
                                <span className="menu__item--title">Suporte</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className={props.mobileMenuOpen ? 'mobile-menu__shadow mobile-menu__shadow--open' : 'mobile-menu__shadow'} ref={mobileMenuShadow} onClick={(e) => {
                        if (e.target !== mobileMenuShadow.current) {
                            return;
                        }
                        props.handleMobileMenuOpen(false);
                    }
                }>
                    <div className="mobile-menu">
                        <NavLink to="/varejistas" exact className="mobile-menu__item" activeClassName="menu__item--active">
                            <span>
                                Início
                            </span>
                        </NavLink>
                        <NavLink to="/varejistas/tendencias" exact className="mobile-menu__item" activeClassName="menu__item--active">
                            <span>
                                Tendências
                            </span>
                        </NavLink>
                        <NavLink to="/varejistas/produtos" exact className="mobile-menu__item" activeClassName="menu__item--active">
                            <span>
                                Descobrir Produtos
                            </span>
                        </NavLink>
                        <NavLink to="/varejistas/favoritos" exact className="mobile-menu__item" onClick={clearCache} activeClassName="menu__item--active">
                            <span>
                                Meus Produtos
                            </span>
                        </NavLink>
                        {/* <NavLink to="/varejistas/minhas-oportunidades" exact className="mobile-menu__item" activeClassName="menu__item--active">
                            <span>
                                Minhas Oportunidades
                            </span>
                        </NavLink> */}
                        <NavLink to="/varejistas/meu-perfil/meus-dados" className="mobile-menu__item" activeClassName="menu__item--active" isActive={(match, location) => {
                            return location.pathname.indexOf("/varejistas/meu-perfil/") >= 0;
                        }}>
                            <span>
                                Meu perfil
                            </span>
                        </NavLink>
                        <NavLink to="/varejistas/suporte" exact className="mobile-menu__item" activeClassName="menu__item--active">
                            <span>
                                Suporte
                            </span>
                        </NavLink>
                        <Link to="/" onClick={handleLogout} className="mobile-menu__item">Sair</Link>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = ({ auth }, props) => {
    return {
      ...props,
      ...auth
    };
};

export default Connect(mapStateToProps)(BuyerHeader);
