import { useState, useEffect } from "react";
import Connect from "../../store/config/connect";
import { useLocation } from 'react-router-dom';
import services from '../../services/services';

const SendTracking = props => {
    const { pathname } = useLocation();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("localev2-user"));

        if (user) {
            const hasCookie = document.cookie.includes('activeUser');
            let date = new Date();

            date.setTime(date.getTime() + (30 * 60 * 1000));

            let expires = "; expires=" + date.toUTCString();

            if (!hasCookie) {
                let hashDevice = btoa(navigator.userAgent);
                let cookieValue = `${user.id}_${hashDevice}_${new Date().getTime()}` // idUser_userAgentBase64_timestamp

                const data = { 'cookieValue': cookieValue }
                document.cookie = 'activeUser=' + cookieValue + ';' + expires + "; path=/";

                services.postCreateLog(data, user.token, (status, response) => {
                    if (status === 200) {
                        console.log(response.msg);
                    }
                });

            } else {
                let value = document.cookie;
                let parts = value.split(`activeUser=`);
                let Cookie = parts.length === 2 ? parts.pop().split(';').shift() : null;

                document.cookie = 'activeUser=' + Cookie + ';' + expires + "; path=/";
            }
        }
    }, [pathname]);

    return null;
}

const mapStateToProps = ({ auth }, props) => {
    return {
        auth,
        ...props
    };
};

export default Connect(mapStateToProps)(SendTracking);
