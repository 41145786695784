import { useState, useEffect } from "react";
import { LogInUser } from '../../store/auth';
import Connect from "../../store/config/connect";
import { useHistory, useLocation } from 'react-router-dom';

const VerifyAuth = props => {
  const [history] = useState(useHistory());
  const { pathname } = useLocation();

  useEffect(() => {
    const user = window.localStorage.getItem('localev2-user')
                ? JSON.parse(window.localStorage.getItem('localev2-user'))
                : "";

    if(user && !props.auth.isLogged){
      props.dispatch(LogInUser(user));
    }
  }, [props, history, pathname]);

  return null;
}

const mapStateToProps = ({ auth }, props) => {
  return {
    auth,
    ...props
  };
};

export default Connect(mapStateToProps)(VerifyAuth);
