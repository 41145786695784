import React, { useReducer, useCallback, useMemo } from "react";
import Context from "./config";
import authReducer, { authStore } from "../auth";
import helperReducer, { helperStore } from '../helper';
import premiumReducer, { premiumStore } from '../premium';
import middleware from "./middleware";

const Store = props => {
  const [authState, authDispatch] = useReducer(authReducer, authStore);
  const [premiumState, premiumDispatch] = useReducer(premiumReducer, premiumStore);
  const [helperState, helperDispatch] = useReducer(helperReducer, helperStore);

  const trigglerDispatchs = action => {
    const dispatchs = [authDispatch, helperDispatch, premiumDispatch];
    for (let i = 0; i < dispatchs.length; i++) {
      dispatchs[i](action);
    }
  };

  const middlewareContrutor = action => {
    middleware(action)(trigglerDispatchs);
  };

  const combinedReducers = useMemo(() => {
    return { 
      store: {
        ...authState,
        ...helperState,
        ...premiumState
      },
      dispatch: middlewareContrutor
    }
  }, [authState, helperState, premiumState]);

  return (
    <Context.Provider value={combinedReducers}>
      {props.children}
    </Context.Provider>
  );
};

export default Store;
