import { createContext } from "react";
import { authStore } from "../auth";
import { premiumStore } from "../premium";
import { helperStore } from "../helper";

export const initialState = {
  store: {
    ...authStore,
    ...helperStore,
    ...premiumStore
  },
  dispatch: () => {}
};

const Context = createContext(initialState);

export default Context;
